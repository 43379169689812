.confirmation {
  font-size: $font-16;
  &__offer-name {
    font-family: "Neutraface2Demi";
    font-size: $font-18;
    text-transform: uppercase;
    padding: 10px 0 6px 0;
  }
  &__booking-code {
    font-family: "Neutraface2Demi";
  }
  &__booking-status {
    text-transform: uppercase;
    margin-left: 8px;
    color: get-color(white);
    background-color: get-color(green);
    padding: 4px 8px;
    font-size: $font-11;
    font-family: "Neutraface2Demi";
    &--yellow {
      color: get-color(grayStandard);
      background-color: get-color(yellow);
    }
  }
  a {
    color: get-color(grayStandard);
    font-family: "Neutraface2Demi";
    cursor: pointer;
  }
}

@media screen and (max-width: $mobile-breakpoint) {
  .confirmation {
    font-size: $font-14;
    &__offer-name {
      font-size: $font-16;
    }
  }
}
