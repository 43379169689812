.hamburger-menu {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  z-index: 1000;
  position: absolute;
  top: 103px;
  bottom: 0;
  left: -200px;
  transition: left 0.5s ease;

  .overlay {
    display: none;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    z-index: 1;
  }
}

.hamburger-menu.open {
  width: 95%;
  min-height: 100vh;

  .overlay {
    display: block;
  }
}

.menu-items {
  width: 95%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  background-color: get-color(white);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  z-index: 9;
  cursor: pointer;
}

.menu-items__item {
  padding: 10px 30px;
  text-decoration: none;
  color: get-color(grayStandard);
  margin: 8px;
  font-size: $font-18;
}

.hamburger-menu-icon {
  display: none;
  cursor: pointer;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  padding: 5px;
  z-index: 10;
  // TBD: Check if there is need to set border if header color is too bright
  //   border: 1px solid get-color(grayStandard);
}

.hamburger-menu-icon .bar {
  width: 30px;
  height: 3px;
  background-color: get-color(white);
  margin: 4px 0;
  transition: 0.4s;
  cursor: pointer;
}
/* Transition to X icon */
.hamburger-menu-icon.open .bar:nth-child(1) {
  transform: rotate(-45deg) translate(-12px, 7px);
}

.hamburger-menu-icon.open .bar:nth-child(2) {
  opacity: 0;
}

.hamburger-menu-icon.open .bar:nth-child(3) {
  transform: rotate(45deg) translate(-8px, -4px);
}

@media screen and (max-width: $mobile-breakpoint) {
  .hamburger-menu-icon {
    display: flex;
    margin-right: 20px;
  }
  .hamburger-menu.open {
    left: 0;
    .menu-items a {
      display: block;
    }
  }
}
